<script setup lang="ts" generic="T">
import type { UISliderWithArrowsProps } from './UISliderWithArrows.props'
import emblaCarouselVue from 'embla-carousel-vue'

const props = withDefaults(defineProps<UISliderWithArrowsProps<T>>(), {
  loop: true,
  hasNoSpaceBetween: false,
  showScrollbar: false,
  showNavigation: true,
  showNavigationNextArrow: true,
  showNavigationPrevArrow: true,
})

const visibleNavigationPanel = ref<null | 'L' | 'R'>(null)

const [emblaRef, emblaApi] = emblaCarouselVue({
  loop: props.loop,
  startIndex: props.loop ? props.items.length : 0,
  skipSnaps: true,
  align: 'start',
  inViewThreshold: 1,
})

const isNavigationNextVisible = ref(props.showNavigationNextArrow)
const isNavigationPrevVisible = ref(props.showNavigationPrevArrow)

const sliderContainer = ref<HTMLElement>()
const { width } = useElementSize(sliderContainer)
const mousemoveHandler = (e: MouseEvent) => {
  const currentTarget = <HTMLTextAreaElement>e.currentTarget
  const x = e.pageX - (currentTarget.offsetLeft ?? 0)

  if (x < width.value / 2) {
    visibleNavigationPanel.value = 'L'
  } else {
    visibleNavigationPanel.value = 'R'
  }
}

const items = computed(() => {
  if (!props.loop) return props.items

  const minSlidesPerView = 10
  let _tempArray = props.items

  while (_tempArray.length < minSlidesPerView * 2) {
    _tempArray = _tempArray.concat(_tempArray)
  }
  return _tempArray
})

const scrollNext = () => {
  emblaApi.value?.scrollNext()
}

const scrollPrev = () => {
  emblaApi.value?.scrollPrev()
}

const scrollBarContainer = ref<HTMLElement>()
const isScrollBarVisible = computed(() => {
  return props.showScrollbar
})

const scrollBarProgress = ref(0)
const scrollBar = ref<HTMLElement>()
const { width: scrollBarWidthDom } = useElementBounding(scrollBar)
const { width: sliderWidthDom } = useElementBounding(scrollBarContainer)

const scrollBarWidthDifference = computed(() => {
  if (!sliderWidthDom.value) return 0
  return sliderWidthDom.value - scrollBarWidthDom.value
})

const { x, isDragging } = useDraggable(scrollBar, {
  initialValue: { x: 0, y: 0 },
  axis: 'x',
  containerElement: sliderContainer,
  onMove(position) {
    const progress = position.x / scrollBarWidthDifference.value
    const { limit, target, scrollProgress, scrollBody, scrollTo } =
      emblaApi.value!.internalEngine()
    const currentProgress = scrollProgress.get(target.get())
    const allowedProgress = Math.min(Math.max(progress, 0), 1)
    const progressToTarget = allowedProgress - currentProgress
    const distance = progressToTarget * limit.length * -1
    scrollBody.useDuration(0)
    scrollTo.distance(distance, false)
  },
})

const scrollBarWidth = computed(() => {
  if (emblaApi.value && emblaRef.value) {
    const emblaWidth =
      sliderWidthDom.value + emblaApi.value?.internalEngine().limit.length

    const scrollbarWidth = (sliderWidthDom.value / emblaWidth) * 100

    return scrollbarWidth
  }
})

const updateScrollBarProgress = () => {
  if (!isDragging.value) {
    x.value =
      (emblaApi.value?.scrollProgress() ?? 0) * scrollBarWidthDifference.value
  }
}

const navigationArrowHandler = () => {
  if (props.showNavigationNextArrow) {
    isNavigationNextVisible.value = emblaApi.value?.canScrollNext() ?? false
  }
  if (props.showNavigationPrevArrow) {
    isNavigationPrevVisible.value = emblaApi.value?.canScrollPrev() ?? false
  }
}

onMounted(() => {
  emblaApi.value?.on('scroll', updateScrollBarProgress)

  emblaApi.value
    ?.on('init', navigationArrowHandler)
    .on('select', navigationArrowHandler)
})
</script>

<template>
  <div
    v-if="items && items.length"
    ref="sliderContainer"
    class="slider-container relative"
    :class="{
      'slider-on-right-edge': rightWindowEdge,
      'slider-with-scrollbar': isScrollBarVisible,
    }"
    @mousemove="(e: any) => mousemoveHandler(e as MouseEvent)"
    @mouseleave="visibleNavigationPanel = null"
  >
    <div
      ref="emblaRef"
      class="embla scrollbar relative"
      :class="{
        'lg:overflow-clip': rightWindowEdge,
      }"
    >
      <div class="embla__container flex cursor-grab select-none" grab-cursor>
        <div
          v-for="(item, index) in items"
          :key="`item__${index}`"
          class="embla__slide shrink-0"
          :class="[
            slideCustomWidth || 'w-6/12',
            !slideCustomWidth
              ? hasNoSpaceBetween
                ? 'lg:!w-1/4'
                : 'lg:!w-1/5'
              : '',
          ]"
        >
          <div class="h-full w-full">
            <slot v-bind="item" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isScrollBarVisible"
      ref="scrollBarContainer"
      class="scrollbar-container absolute bottom-0 left-0 right-0 hidden !w-[calc(100%_-_var(--padding-x))] overflow-clip lg:flex"
    >
      <div
        ref="scrollBar"
        class="scroll-bar-progress swiper-scrollbar-drag"
        :style="{
          width: `${scrollBarWidth}%`,
          transform: `translateX(${x}px)`,
        }"
      />
    </div>
    <button
      v-if="
        showNavigation &&
        isNavigationPrevVisible &&
        visibleNavigationPanel === 'L'
      "
      aria-label="prev"
      class="navigation-button navigation-button-prev bg-neutral-white absolute -left-[2px] z-10 -translate-x-full cursor-pointer items-center justify-center"
      :class="[
        hasSlideVerticalBorder
          ? `top-[1px] h-[calc(100%_-_2px)]`
          : 'top-0 h-full',
      ]"
      @click="scrollPrev"
    >
      <DefaultIconsChevronLeft aria-hidden="true" />
    </button>
    <button
      v-if="
        showNavigation &&
        isNavigationNextVisible &&
        visibleNavigationPanel === 'R'
      "
      aria-label="next"
      class="navigation-button navigation-button-next bg-neutral-white absolute top-0 z-10 translate-x-full cursor-pointer items-center justify-center"
      :class="[
        hasSlideVerticalBorder
          ? `top-[1px] h-[calc(100%_-_2px)]`
          : 'top-0 h-full',
        hasNoSpaceBetween
          ? '-right-[1px]'
          : slideCustomWidth
            ? '-right-[3px]'
            : '-right-[5px]',
      ]"
      @click="scrollNext"
    >
      <DefaultIconsChevronRight aria-hidden="true" />
    </button>
  </div>
</template>

<style scoped lang="scss">
.slider-on-right-edge {
  @screen lg {
    margin-right: calc(var(--padding-x) * -1);
    .embla {
      padding-right: var(--padding-x) !important;
    }
    .navigation-button-next {
      transform: translateX(0);
    }
  }
}

.slider-with-scrollbar {
  .embla {
    padding-bottom: 1.5rem !important;
  }
  .navigation-button-prev,
  .navigation-button-next {
    height: calc(100% - 1.5rem);
  }
}

.slider-container {
  &:hover {
    .navigation-button {
      opacity: 1;
    }
  }
}

.navigation-button {
  width: var(--padding-x);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: auto;
  display: none;

  @screen md {
    display: flex;
  }

  &:hover {
    opacity: 1;
  }
}

.scroll-bar-progress {
  height: 4px;
  border-radius: 3px;
  background: var(--Colors-Overlay-Black-10, rgba(0, 0, 0, 0.1));
  cursor: grab;
}
</style>
